"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCurrentMonth = exports.MonthKind = void 0;
const luxon_1 = require("luxon");
const react_1 = require("react");
const generated_1 = require("uni/graphql/hooks/generated");
var MonthKind;
(function (MonthKind) {
    MonthKind[MonthKind["FIRST_MONTH"] = 0] = "FIRST_MONTH";
    MonthKind[MonthKind["NO_USAGE"] = 1] = "NO_USAGE";
    MonthKind[MonthKind["SOME_USAGE"] = 2] = "SOME_USAGE";
    MonthKind[MonthKind["REPORT_BUILDING"] = 3] = "REPORT_BUILDING";
})(MonthKind = exports.MonthKind || (exports.MonthKind = {}));
const useCurrentMonth = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    const { data, loading, error } = (0, generated_1.useGetWorkspaceUsageQuery)({});
    const workspaceUsage = data === null || data === void 0 ? void 0 : data.workspace.usage;
    const allTeamMembers = (0, react_1.useMemo)(() => { var _a; return ((_a = data === null || data === void 0 ? void 0 : data.me.team) === null || _a === void 0 ? void 0 : _a.members) || []; }, [(_a = data === null || data === void 0 ? void 0 : data.me.team) === null || _a === void 0 ? void 0 : _a.members]);
    const isTeamPlan = (data === null || data === void 0 ? void 0 : data.me.team) ? true : false;
    const isTeamMember = data === null || data === void 0 ? void 0 : data.me.isTeamMember;
    const isFirstMonth = !isTeamMember &&
        workspaceUsage &&
        luxon_1.DateTime.now() < luxon_1.DateTime.fromISO(workspaceUsage.startDate).endOf('month');
    const teamUsages = (0, react_1.useMemo)(() => {
        return allTeamMembers
            .filter((x) => x)
            .sort((a, b) => {
            // Descending order of usage
            const bMonth = b.workspaceUsage && b.workspaceUsage.monthlyUsages[0];
            const aMonth = a.workspaceUsage && a.workspaceUsage.monthlyUsages[0];
            return ((bMonth && bMonth.hours) || 0) - ((aMonth && aMonth.hours) || 0);
        });
    }, [allTeamMembers]);
    const publicTeamUsageHours = ((_c = (_b = data === null || data === void 0 ? void 0 : data.me) === null || _b === void 0 ? void 0 : _b.team) === null || _c === void 0 ? void 0 : _c.hours) || 0;
    const selfUsageFromTeam = (0, react_1.useMemo)(() => {
        const self = allTeamMembers.find((member) => {
            return member.isSelf;
        });
        return self && self.workspaceUsage;
    }, [allTeamMembers]);
    // Use workspaceUsage first because it refers to the workspace not the executiveId
    const selfUsage = workspaceUsage || selfUsageFromTeam;
    const includedHours = ((_e = (_d = data === null || data === void 0 ? void 0 : data.me) === null || _d === void 0 ? void 0 : _d.pricing) === null || _e === void 0 ? void 0 : _e.includedHours) || 0;
    // || // For team lead
    // (selfUsage && selfUsage.hoursLimit) // Team member does not have pricing, use team member hours limit
    const hoursLimit = selfUsage === null || selfUsage === void 0 ? void 0 : selfUsage.hoursLimit;
    const isAllowedPersonal = selfUsage === null || selfUsage === void 0 ? void 0 : selfUsage.isAllowedPersonal;
    const pricingId = (_g = (_f = data === null || data === void 0 ? void 0 : data.me) === null || _f === void 0 ? void 0 : _f.pricing) === null || _g === void 0 ? void 0 : _g.id;
    const pricingLabel = (_j = (_h = data === null || data === void 0 ? void 0 : data.me) === null || _h === void 0 ? void 0 : _h.pricing) === null || _j === void 0 ? void 0 : _j.label;
    const hourlyPrice = (_l = (_k = data === null || data === void 0 ? void 0 : data.me) === null || _k === void 0 ? void 0 : _k.pricing) === null || _l === void 0 ? void 0 : _l.hourlyPrice;
    const hasSomeTeamUsage = (0, react_1.useMemo)(() => {
        return teamUsages.some((member) => {
            return (member.workspaceUsage &&
                member.workspaceUsage.monthlyUsages.some((usage) => {
                    return usage.hours > 0;
                }));
        });
    }, [teamUsages]);
    const hasSomeSelfUsage = (0, react_1.useMemo)(() => {
        return (selfUsage &&
            selfUsage.monthlyUsages.some((usage) => {
                return usage.hours > 0;
            }));
    }, [selfUsage]);
    const currentMonth = (0, react_1.useMemo)(() => {
        if (selfUsage) {
            const common = {
                selfUsage,
                teamUsages,
                includedHours,
                hoursLimit,
                isAllowedPersonal,
                pricingLabel,
                pricingId,
                hourlyPrice,
                isTeamPlan,
                isTeamMember,
                publicTeamUsageHours,
            };
            if (isFirstMonth) {
                return Object.assign({ kind: MonthKind.FIRST_MONTH }, common);
            }
            else if (!hasSomeSelfUsage && !hasSomeTeamUsage) {
                return Object.assign({ kind: MonthKind.NO_USAGE }, common);
            }
            else {
                return Object.assign({ kind: MonthKind.SOME_USAGE }, common);
            }
        }
        else {
            return undefined;
        }
    }, [
        selfUsage,
        teamUsages,
        includedHours,
        hoursLimit,
        isAllowedPersonal,
        pricingLabel,
        pricingId,
        hourlyPrice,
        isTeamPlan,
        isTeamMember,
        isFirstMonth,
        hasSomeSelfUsage,
        hasSomeTeamUsage,
        publicTeamUsageHours,
    ]);
    return {
        currentMonth,
        loading,
        error,
    };
};
exports.useCurrentMonth = useCurrentMonth;
