"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const react_router_1 = require("react-router");
const useNavigateToWorkspace = () => {
    const history = (0, react_router_1.useHistory)();
    return (0, react_1.useCallback)((workspaceId) => {
        if (workspaceId) {
            history.push(`/workspaces/${workspaceId}`);
        }
        else {
            history.push(`/`);
        }
    }, [history]);
};
exports.default = useNavigateToWorkspace;
