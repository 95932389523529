"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSlotIntersectionWithBusinessHours = exports.slotIsInBusinessHours = exports.dateIsInBusinessHours = exports.addBusinessHours = exports.addBusinessDays = void 0;
const luxon_1 = require("luxon");
// @ts-ignore - 💩 this module is not typed
const luxon_business_days_1 = require("luxon-business-days");
// 💩 workaround for the non-typed module so we don't lose DateTime typing
const addBusinessDays = (date, daysToAdd) => {
    const businessDateTime = luxon_business_days_1.DateTime.fromObject(date.toObject(), {
        zone: date.zoneName,
    });
    const newDate = businessDateTime.plusBusiness({ days: daysToAdd });
    return luxon_1.DateTime.fromObject(newDate.toObject(), { zone: date.zoneName });
};
exports.addBusinessDays = addBusinessDays;
const addBusinessHours = ({ date, businessHours }, hoursToAdd) => {
    let newDate = date.plus({ hours: hoursToAdd });
    // make sure we didn't rollover into a weekend
    if (newDate.weekday > 5) {
        newDate = (0, exports.addBusinessDays)(newDate, 1);
    }
    if (!businessHours) {
        return newDate;
    }
    if (newDate.hour >= businessHours.endHour) {
        // if over end hours, skip to next day and set overage hours
        const newHours = businessHours.startHour +
            Math.min(newDate.hour - businessHours.endHour, hoursToAdd);
        newDate = newDate.set({
            hour: newHours,
            minute: date.minute,
        });
        newDate = (0, exports.addBusinessDays)(newDate, 1);
    }
    else if (newDate.hour < businessHours.startHour) {
        // if under start hours, skip to start hours and add hours
        newDate = newDate.set({
            hour: businessHours.startHour + hoursToAdd,
            minute: date.minute,
        });
    }
    return newDate;
};
exports.addBusinessHours = addBusinessHours;
const getBusinessHoursInterval = (date, businessHours) => {
    const startingHour = date
        .setZone(businessHours.timeZoneName)
        .set({ hour: businessHours.startHour })
        .startOf('hour');
    const endingHour = startingHour.set({
        hour: businessHours.endHour,
    });
    return luxon_1.Interval.fromDateTimes(startingHour, endingHour);
};
const dateIsInBusinessHours = (date, businessHours) => {
    const businessHoursInterval = getBusinessHoursInterval(date, businessHours);
    return businessHoursInterval.contains(date);
};
exports.dateIsInBusinessHours = dateIsInBusinessHours;
const slotIsInBusinessHours = ({ start, end, }, businessHours) => {
    if (!end) {
        return (0, exports.dateIsInBusinessHours)(start, businessHours);
    }
    const businessHoursInterval = getBusinessHoursInterval(start, businessHours);
    return !!businessHoursInterval.engulfs(luxon_1.Interval.fromDateTimes(start, end));
};
exports.slotIsInBusinessHours = slotIsInBusinessHours;
const getSlotIntersectionWithBusinessHours = ({ start, end, }, businessHours) => {
    const businessHoursInterval = getBusinessHoursInterval(start, businessHours);
    return businessHoursInterval.intersection(luxon_1.Interval.fromDateTimes(start, end));
};
exports.getSlotIntersectionWithBusinessHours = getSlotIntersectionWithBusinessHours;
