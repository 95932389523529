"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatDuration = exports.formatJSDateToDate = exports.formatJSDateToTime = exports.formatEndDtToISO = exports.formatStartDtToISO = exports.NUM_DAYS = void 0;
const luxon_1 = require("luxon");
exports.NUM_DAYS = 5;
const formatStartDtToISO = (dt) => dt.startOf('day').toISO();
exports.formatStartDtToISO = formatStartDtToISO;
const formatEndDtToISO = (dt) => dt.plus({ day: exports.NUM_DAYS }).endOf('day').toISO();
exports.formatEndDtToISO = formatEndDtToISO;
const formatJSDateToTime = (d, zoneName) => formatJSDateToDt(d).setZone(zoneName).toFormat('h:mma').toLowerCase();
exports.formatJSDateToTime = formatJSDateToTime;
const formatJSDateToDate = (d, zoneName) => formatJSDateToDt(d).setZone(zoneName).toFormat('DDDD');
exports.formatJSDateToDate = formatJSDateToDate;
const formatJSDateToDt = (d) => luxon_1.DateTime.fromJSDate(d);
const computeDurationFromStartTime = (startDt, endDt) => {
    const minutesFromStartTime = Math.round(endDt.diff(startDt, ['minutes']).minutes);
    const remainderMins = minutesFromStartTime % 60;
    const hoursFromStartTime = Math.round(minutesFromStartTime / 60);
    const daysFromStartTime = Math.round(hoursFromStartTime / 24);
    return {
        minutesFromStartTime,
        remainderMins,
        hoursFromStartTime,
        daysFromStartTime,
    };
};
const formatDuration = (start, end) => {
    const startDt = formatJSDateToDt(start);
    const endDt = formatJSDateToDt(end);
    const { minutesFromStartTime, remainderMins, hoursFromStartTime, daysFromStartTime, } = computeDurationFromStartTime(startDt, endDt);
    if (minutesFromStartTime < 60) {
        return `${minutesFromStartTime}m`;
    }
    else if (minutesFromStartTime >= 60 && hoursFromStartTime < 24) {
        return `${hoursFromStartTime}h ${remainderMins !== 0 ? `${remainderMins}m` : ''}`;
    }
    else if (hoursFromStartTime >= 24) {
        return `${daysFromStartTime}d`;
    }
    return '';
};
exports.formatDuration = formatDuration;
